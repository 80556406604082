



































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Indicator, IndicatorByRegion } from "@/models/Indicator";
import {
  INDICATOR_REGION_CU,
  INDICATOR_REGION_DELETE,
} from "@/store/actions/indicator";
import { Region } from "@/models/Region";
import { REGION_REQUEST, REGION_LOADED } from "@/store/actions/main";
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);

@Component({
  components: {
    vueCustomScrollbar,
  },
})
export default class IndicatorsRegion extends Vue {
  private indicatorName = "";
  private form: IndicatorByRegion = new IndicatorByRegion();
  private regions: any = [];
  private usedRegions: any = [];
  private yearInput: number = new Date().getFullYear();
  private savedHeaders: any = [];
  private headers: any = [
    {
      text: "Регион",
      value: "col0",
      align: "center",
      sortable: false,
      width: "150px",
    },
  ];
  private pages: number = 1;
  private currentPage: number = 0;
  private yearGap: number = 5;
  private focusedYear = 0;
  private items: any = [];
  private options = {
    page: 0,
    itemsPerPage: -1,
  };
  private loading: boolean = true;
  //Snackbar
  private messageText = "Не удалось выполнить";
  private messageColor = "red";
  private message = false;
  //Scrollbar
  private scrollbarSettings = {
    suppressScrollY: false,
    suppressScrollX: false,
    wheelPropagation: false,
  };
  //Interface
  private UIUpdateField = false;
  private UIUpdateSuccess = false;
  private UIUpdateFailed = false;
  private currentRow = 0;
  private currentCol = 0;
  private currentHeaderCol = "";
  private headerFocused = false;

  //Initialise methods
  private initIfReady() {
    //return true if started to init self.
    let needReload = [] as boolean[];
    needReload.push(
      this.needReload(
        this.$store.getters.REGION_TABLE,
        this.$store.getters.REGION_LOADED
      )
    );
    for (let i = 0; i < needReload.length; i++) {
      if (needReload[i] == true) {
        return false;
      }
    }
    this.initSelf();
    return true;
  }
  private needReload(table, updated) {
    /*
	data is a table loading from request.
	updated is flag that is false when table has old data and need to be reloading.
	return true if need reload.
	*/
    if (table == null || !updated) return true;
    return false;
  }
  private tryToInitSelf() {
    this.loading = true;
    //const data = this.getParams();
    if (this.initIfReady()) return;
    if (
      this.needReload(
        this.$store.getters.REGION_TABLE,
        this.$store.getters.REGION_LOADED
      )
    ) {
      this.$store.dispatch(REGION_REQUEST).then(() => {
        this.initIfReady();
      });
    }
  }
  private initSelf() {
    this.regions = this.$store.getters.REGION_TABLE.data;
    var item;
    if (localStorage.getItem("indicatorToEdit")) {
      try {
        item = JSON.parse(
          this.toString(localStorage.getItem("indicatorToEdit"))
        );
        console.log("item init: ", item);
      } catch (e) {
        localStorage.removeItem("indicatorToEdit");
      }
    }
    this.indicatorName = item.indicator_name;
    this.form.indicator_id = item.id;
    var ibr = item.indicator_by_region;
    console.log("regions", this.regions);
    console.log("ibr", ibr);
    //Заполняем заголовки
    for (let i = 0; i < ibr.length; i++) {
      let inYears = false;
      for (let j = 1; j < this.headers.length; j++) {
        if (ibr[i].year == parseInt(this.headers[j].text, 10)) inYears = true;
      }
      if (!inYears) {
        this.addColumn(ibr[i].year, true);
      }
    }
    //Заполняем объекты (ряды)
    //Сначала пустыми данными
    for (let i = 0; i < this.regions.length; i++) {
      this.items.push({
        col0: null,
        row: i,
      });
    }
    let years = [] as number[];
    for (let i = 0; i < ibr.length; i++) {
      let isExist = false;
      for (let j = 0; j < years.length; j++) {
        if (years[j] == ibr[i].year) isExist = true;
      }
      if (!isExist) years.push(ibr[i].year);
    }
    years.sort(function (a, b) {
      return a - b;
    });
    console.log("years", years);
    for (let i = 0; i < this.items.length; i++) {
      for (let j = 0; j < years.length; j++) {
        this.items[i]["col" + (j + 1)] = null;
      }
    }
    console.log("headers", this.headers);
    //Затем данными из таблицы
    for (let i = 0; i < ibr.length; i++) {
      for (let j = 0; j < this.regions.length; j++) {
        if (this.regions[j].id == ibr[i].region_id) {
          let col = this.getColFromYear(ibr[i].year);
          this.items[j][col] = ibr[i].indicator_value;
        }
      }
    }
    this.saveHeaders();
    this.pages = Math.ceil((this.headers.length - 1) / this.yearGap);
    if (this.pages == 0) this.pages = 1;
    console.log("items start fill:", this.items);
    //this.addColumnItems();
  }
  //Methods
  private onBlur() {
    this.headerFocused = false;
    console.log("blur");
  }
  private onFocus(header) {
    this.focusedYear = parseInt(header.text, 10);
    console.log("onFocus year:", this.focusedYear);
    // this.headerFocused = true;
    this.currentHeaderCol = header.value;
  }
  private getPagedHeaders() {
    //let paged = [...this.headers]
    let pagedHeaders = [] as any;
    pagedHeaders.push(this.headers[0]);
    for (let i = 1; i < this.headers.length; i++) {
      if (this.isOnCurrentPage(i - 1)) {
        pagedHeaders.push(this.headers[i]);
      }
    }
    return pagedHeaders;
  }
  // private getHeaderSlot(header){
  //   //'header.${header.value}'
  //   let i = 0;
  //   h: for(i = 0; i < this.headers.length; i++){
  //     if(this.headers[i].value == header.value)
  //       break h;
  //   }
  //   if(this.isOnCurrentPage(i))
  //     return 'header.'+header.value;
  //   return false;
  // }
  // private getItemSlot(header){
  //   //'header.${header.value}'
  //   let i = 0;
  //   h: for(i = 0; i < this.headers.length; i++){
  //     if(this.headers[i].value == header.value)
  //       break h;
  //   }
  //   if(this.isOnCurrentPage(i))
  //     return 'item.'+header.value;
  //   return false;
  // }
  private isOnCurrentPage(n) {
    let a = this.currentPage * this.yearGap;
    let b = this.currentPage * this.yearGap + this.yearGap;
    if (n >= a && n < b) {
      return true;
    }
    return false;
  }
  private nextPage() {
    if (this.currentPage < this.pages - 1) this.currentPage++;
  }
  private prevPage() {
    if (this.currentPage > 0) this.currentPage--;
  }
  private saveHeaders() {
    //this.savedHeaders = [...this.headers];
    this.savedHeaders.length = 0;
    for (let i = 0; i < this.headers.length; i++) {
      this.savedHeaders.push({
        text: "" + this.headers[i].text,
        value: "" + this.headers[i].value,
        align: "center",
        sortable: false,
      });
    }
    console.log("headers saved!");
  }
  private changeHeader(header) {
    console.log("changed h", header);
    let exist = this.isYearExist(parseInt(header.text, 10), this.savedHeaders);
    let year = parseInt(header.text, 10);
    console.log("headers", this.headers);
    console.log("s headers", this.savedHeaders);
    if (!exist) {
      let yearToDelete = -1;
      for (let i = 0; i < this.savedHeaders.length; i++) {
        if (this.savedHeaders[i].value == header.value)
          yearToDelete = parseInt(this.savedHeaders[i].text, 10);
      }
      if (yearToDelete < 0) {
        return;
      }
      //Заполняем колонку с введенным годом
      for (let i = 0; i < this.regions.length; i++) {
        console.log("col", header.value);
        console.log("y", year);
        if(this.items[i][header.value] != null){
          this.fieldChange(
            this.items[i].row,
            this.items[i][header.value],
            header.value
          );
        }
      }
      this.currentRow = -1;
      //Удаляем колонку со старым годом
      console.log("year to delete", yearToDelete);
      this.deleteYear(yearToDelete);
      this.headers.sort(function (a, b) {
        return parseInt(a.text, 10) - parseInt(b.text, 10);
      });
      this.saveHeaders();
    } else {
      console.log("year " + header.text + " exist");
      var n = this.getHeaderNum(header);
      if (this.focusedYear >= 0) this.headers[n].text = this.focusedYear;
      this.messageText = "Введенный год уже существует.";
      this.messageColor = "red";
      this.message = true;
    }

    // for(let i = 0; i < this.items.length; i++){
    //   if(this.items[i][header.value] )
    // }
    // this.deleteColumn(n);
  }
  private getColFromYear(year) {
    for (let i = 1; i < this.headers.length; i++) {
      var y = parseInt(this.headers[i].text, 10);
      if (year == y) return this.headers[i].value;
    }
    return "colNaN";
  }
  private isYearExist(year, headers) {
    let exist = false;
    for (let i = 0; i < headers.length; i++) {
      if (year == parseInt(headers[i].text, 10)) {
        exist = true;
      }
    }
    return exist;
  }
  private addColumn(year, init) {
    if (year < 0) {
      year = 1 + parseInt(this.headers[this.headers.length - 1].text, 10);
    }

    let exist = this.isYearExist(year, this.headers);

    if (!exist) {
      this.headers.push({
        text: "" + year,
        value: "col" + this.headers.length,
        align: "center",
        sortable: false,
      });

      this.headers.sort(function (a, b) {
        return parseInt(a.text, 10) - parseInt(b.text, 10);
      });

      if (!init) {
        // Запрос на заполнение полей и локальное заполнение
        for (let i = 0; i < this.regions.length; i++) {
          console.log("col to ch", this.getColFromYear(year));
          this.items[i][this.getColFromYear(year)] = null;
          this.items[i].row = i;
          console.log("year to change", year);
          //this.fieldChange(this.items[i].row, 0, this.getColFromYear(year));
        }
        this.currentRow = -1;
        this.currentCol = -1;
      }
    } else {
      this.messageText = "Введенный год уже существует в таблице.";
      this.messageColor = "red";
      this.message = true;
    }
    this.yearInput = this.getNotFoundedYear();
    this.saveHeaders();
  }
  // private removeLastColumn() {
  //   let n = this.headers.length - 1;
  //   this.deleteColumn(n);
  //   // for (let i = 0; i < this.regions.length; i++) {
  //   //   delete this.items[i][this.headers[n].value];
  //   // }
  //   // this.headers.splice(n, 1);
  // }
  // private addColumnItems() {
  //   for (let i = 0; i < this.regions.length; i++) {
  //     //Заполнить объекты новыми полями
  //     this.items[i].row = i;
  //     this.items[i][this.headers[this.headers.length - 1].value] = 0;
  //   }
  //   console.log("items:", this.items);
  // }
  private getHeaderNum(header) {
    for (let i = 0; i < this.headers.length; i++) {
      if (header.value == this.headers[i].value) return i;
    }
    return -1;
  }
  private getNotFoundedYear() {
    let year = 0;
    if (this.headers.length > 1) {
      let y = parseInt(this.headers[1].text) + 1;
      activeYear: for (let i = 2; i < this.headers.length; i++) {
        if (
          parseInt(this.headers[i].text) !=
          parseInt(this.headers[i - 1].text) + 1
        ) {
          y = parseInt(this.headers[i - 1].text) + 1;
          break activeYear;
        }
        if (i == this.headers.length - 1) {
          y = parseInt(this.headers[i].text) + 1;
        }
      }
      year = y;
    } else {
      year = new Date().getFullYear();
    }
    return year;
  }
  private deleteColumn(header) {
    // for (let i = 0; i < this.regions.length; i++) {
    //   this.items[i][this.headers[n].value] = 0;
    //   console.log("col ", this.headers[n].text);
    //   console.log("row ", this.items[i].row);
    //   this.fieldChange(this.items[i].row, 0, this.headers[n].value);
    // }
    let exist = this.isYearExist(+parseInt(header.text), this.headers);
    console.log("this exist - ", exist);
    if (exist) {
      this.focusedYear = -1;
      let n = this.getHeaderNum(header);
      let data = {
        indicator_id: this.form.indicator_id,
        params: {
          year: +parseInt(this.headers[n].text),
        },
      };
      this.$store
        .dispatch(INDICATOR_REGION_DELETE, data)
        .then(() => {
          this.messageText = "Год " + data.params["year"] + " удален";
          this.messageColor = "green";
          for (let i = 0; i < this.regions.length; i++) {
            delete this.items[i][this.headers[n].value];
          }
          this.headers.splice(n, 1);
          this.saveHeaders();
        })
        .catch(() => {
          this.messageText = "Не удалось выполнить";
          this.messageColor = "red";
        })
        .finally(() => {
          this.message = true;
        });
    } else {
      this.messageText = "Невозможно удалить год, т.к. обнаружен дубликат.";
      this.messageColor = "red";
      this.message = true;
    }
  }
  private deleteYear(y) {
    let data = {
      indicator_id: this.form.indicator_id,
      params: {
        year: +y,
      },
    };
    this.$store
      .dispatch(INDICATOR_REGION_DELETE, data)
      .then(() => {
        this.messageText = "Год " + data.params["year"] + " удален";
        this.messageColor = "green";
      })
      .catch(() => {
        this.messageText = "Не удалось выполнить";
        this.messageColor = "red";
      })
      .finally(() => {
        this.message = true;
      });
  }
  private toString(str) {
    return str as string;
  }
  //Events
  private fieldInput() {
    console.log("fieldInput");
  }
  //Ряд, Значение, 'col{n}'
  private fieldChange(row, value, col) {
    this.UIUpdateField = true;
    this.currentRow = row;
    console.log("fc item");
    console.log("col", col);
    this.form.region_id = this.regions[row].id;
    //var num = col.replace(/^\D+/g, "");
    var num = 1;
    for (let i = 1; i < this.headers.length; i++) {
      if (this.headers[i].value == col) num = i;
    }
    this.currentCol = num;
    this.form.year = +this.headers[num].text;
    this.form.indicator_value = +value;
    var converted = new FormData();
    Object.keys(this.form).forEach((key) => {
      if (typeof this.form[key] !== "undefined")
        converted.append(key, this.form[key]);
      else console.log("undefined:", key);
    });
    this.$store
      .dispatch(INDICATOR_REGION_CU, converted)
      .then(() => {
        this.messageText =
          "Показатель " +
          this.indicatorName +
          " региона " +
          this.regions[row].name +
          " за год " +
          this.form.year +
          " изменен на " +
          this.form.indicator_value;
        this.messageColor = "green";
        //this.tryToInitSelf();
        console.log("focusNow", this.focusedYear);
        this.focusedYear = this.headers[num].text;
        this.UIUpdateSuccess = true;
        this.UIUpdateFailed = false;
      })
      .catch(() => {
        this.messageText = "Не удалось выполнить";
        this.messageColor = "red";
        this.UIUpdateSuccess = false;
        this.UIUpdateFailed = true;
      })
      .finally(() => {
        this.message = true;
        this.UIUpdateField = false;
        // this.currentRow = -1;
        // this.currentCol = -1;
      });
  }
  //Hooks
  private created() {
		if(localStorage.getItem("indicatorTitle")){
			let title = localStorage.getItem("indicatorTitle");
			this.$router.currentRoute.meta.breadcrumb[2].text = title;
		}
  }
  private mounted() {
    this.tryToInitSelf();
  }
  private updated() {
    this.pages = Math.ceil((this.headers.length - 1) / this.yearGap);
    if (this.pages == 0) this.pages = 1;
  }
  //Interface Methods
  private isFieldUpdating(row, header) {
    var headerNum = this.getHeaderNum(header);
    if (this.currentRow == row && this.currentCol == headerNum) return true;
    return false;
  }
  private isHeaderUpdating(header) {
    //if (this.currentHeaderCol == header.value && this.headerFocused) return true;
    if (this.headerFocused) return true;
    return false;
  }
  private backBackBack() {
    this.$router.push("/pages/indicators");
  }
  private widthStyle() {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return "";
      case "sm":
        return "max-width: 200px !important";
      case "md":
        return "max-width: 200px !important";
      case "lg":
        return "max-width: 200px !important";
      case "xl":
        return "max-width: 200px !important";
      default:
        return "max-width: 200px !important";
    }
  }
}
